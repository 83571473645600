<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4>Attachment</h4>
      <div class="position-relative">
        <input type="text" class="form-control" v-model="searchText" />
        <span class="bx bx-search-alt"></span>
      </div>
    </div>
    <div class="container">
      <!-- upload file -->
      <b-form-group label="Upload File" label-for="upload">
        <uploader
          :autoStart="false"
          :ref="'template_upload'"
          :options="renderUploadOptions()"
          @filesSubmitted="processUpload()"
          class="uploader-example"
        >
          <uploader-unsupport></uploader-unsupport>
          <uploader-drop class="d-flex">
            <p>Drop files here to upload or</p>
            <uploader-btn class="ms-2 btn btn-primary"
              >select files</uploader-btn
            >
          </uploader-drop>
          <uploader-list></uploader-list>
        </uploader>
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            class="btn mt-4"
            @click="processUpload"
            >Upload</b-button
          >
        </div>
      </b-form-group>
      <div v-if="attachmentData.length">
        <div class="d-flex justify-content-between align-items-center">
          <h5>List of Files</h5>
          <b-dropdown
            text="Button text via Prop"
            class="ms-auto"
            variant="white"
            toggle-tag="a"
            menu-class="dropdown-menu-end"
            toggle-class="font-size-16 p-0"
            right
          >
            <template #button-content>
              <i class="mdi mdi-dots-horizontal"></i>
            </template>
            <b-dropdown-item @click="download"
              >Download All Files</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div class="dropdown-divider"></div>
        <div>
          <table class="w-100">
            <thead>
              <tr>
                <td v-for="item in fields" :key="item" v-text="item" />
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(file, i) in attachmentData" :key="i">
                <td
                  v-for="key in fileKey"
                  :key="key"
                  :style="{ width: width[key] }"
                >
                  {{ file[key] }}
                </td>
                <td>
                  <b-dropdown
                    text="Button text via Prop"
                    class="ms-auto"
                    variant="white"
                    toggle-tag="a"
                    menu-class="dropdown-menu-end"
                    toggle-class="font-size-16 p-0"
                    right
                  >
                    <template #button-content>
                      <i class="mdi mdi-dots-horizontal"></i>
                    </template>
                    <b-dropdown-item @click="downloadSingleFile(file.src)"
                      >Download</b-dropdown-item
                    >
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      searchText: "",
      file: "",
      dragging: false,
      files: [],
      fields: ["Name", "Date modified", "Size"],
      fileKey: ["name", "date", "file_size"],
      width: { name: "50%", date: "25%", file_size: "25%" },
    };
  },
  props: {
    attachment: {
      type: Array,
    },
    requestId: {
      type: Number,
    },
  },
  watch: {},
  methods: {
    renderUploadOptions() {
      var storage = window.localStorage;
      var AUTH_TOKEN = storage.getItem("x_token");
      var url =
        process.env.VUE_APP_API_BASEURL + "api/requests/uploadattachment/";
      var options = {
        target: url,
        query: {
          request_id: this.requestId,
        },
        headers: {
          "Access-Control-Allow-Credentials": true,
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + AUTH_TOKEN,
        },
        testChunks: false,
        fileParameterName: "attachment",
        singleFile: true,
      };
      return options;
    },
    processUpload() {
      return new Promise((resolve, reject) => {
        const uploaderInstance = this.$refs["template_upload"];
        const upload = uploaderInstance.uploader;
        upload.opts.query.request_id = this.requestId;
        upload.resume();
        upload.on("fileSuccess", (rootFile, file, message) => {
          var response = JSON.parse(message);
          if (response.success) {
            console.log("Upload success", file);
            this.$eventHub.$emit("loadReports");
            resolve();
            this.$emit("refresh");
          } else {
            console.log("Upload error");
            reject();
          }
        });
        setTimeout(() => {
          upload.off("fileSuccess");
          upload.cancel();
        }, 2000);
      });
    },
    download(src) {
      console.log("src" + src);

      var storage = window.localStorage;
      var AUTH_TOKEN = storage.getItem("x_token");

      axios({
        url:
          process.env.VUE_APP_API_BASEURL +
          "api/requests/zipattachments/" +
          AUTH_TOKEN +
          "/" +
          this.requestId,
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "arraybuffer",
      }).then((res) => {
        if (res.data !== null && res.data.length > 1) {
          console.log(res.data);
        }

        const blob = new Blob([res.data], { type: "application/zip" });
        const a = document.createElement("a");
        a.download = `file.zip`;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        a.remove();
      });
    },
    downloadSingleFile(src) {
      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.href = process.env.VUE_APP_API_BASEURL + src;
      link.download = "file";
      document.body.appendChild(link);
      link.click();
    },
  },
  computed: {
    attachmentData() {
      return this.attachment
        .map((item) => {
          return {
            id: item.id,
            name: item.src.split("/").pop(),
            date: new Date(item.created_at).toLocaleString([], {
              year: "numeric",
              month: "numeric",
              day: "2-digit",
            }),
            file_size: "",
            src: item.src,
          };
        })
        .filter((item) => {
          return item.name.includes(this.searchText);
        });
    },
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 0 40px;
}

.item {
  margin-bottom: 25px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 14px;
  margin-bottom: 0;
}

body[data-topbar="dark"] .app-search .form-control {
  background: #eff2f7;
}

body[data-topbar="dark"] .app-search span,
body[data-topbar="dark"]
  .app-search
  input.form-control::-webkit-input-placeholder {
  color: black;
}

.app-search .form-control {
  border-radius: 4px;
}

.app-search {
  padding: 0;
}

thead td {
  font-weight: bold;
}

tr {
  border-bottom: 1px solid #eff2f7;
}

td {
  word-break: break-all;
  padding: 5px;
}

.uploader-example ::v-deep .uploader-file-actions > span {
  display: none !important;
}

.uploader-btn {
  color: #556ee6;
  border-color: #556ee6;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  font-size: 0.8125rem;
  border-radius: 0.25 rem;
}

.bx {
  position: absolute;
  font-size: 16px;
  line-height: 38px;
  top: 0%;
  left: 13px;
}

.form-control {
  padding-left: 40px;
}
</style>
