<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h4>Approval Chain</h4>
      <div class="d-flex">
        <div>
          <vue-typeahead-bootstrap
            class="me-2"
            v-model="approvalUser"
            :showOnFocus="true"
            :data="approvalDropdown"
            placeholder="Add a user to approval chain"
          />
        </div>
        <b-button variant="outline-primary" class="btn" @click="addApprovalUser"
          >Add</b-button
        >
      </div>
    </div>
    <div class="container">
      <draggable v-model="list" @change="changeOrder()">
        <div
          v-for="(item, i) in chainData"
          :key="i"
          class="item d-flex justify-content-between"
        >
          <div class="d-flex align-items-center">
            <span class="me-2 font-weight-bold">{{ i + 1 }}</span>
            <span
              class="icon me-2 d-flex align-items-center justify-content-center"
            >
              {{ item["approval_user_name"].slice(0, 1) }}</span
            >{{ item["approval_user_name"] }}
          </div>
          <div class="d-flex align-items-center">
            <div class="badge" :class="statusClass(item.status)">
              {{ item.status }}
            </div>
            <b-dropdown
              text="Button text via Prop"
              class="ms-auto"
              variant="white"
              toggle-tag="a"
              menu-class="dropdown-menu-end"
              toggle-class="font-size-16 p-0"
              right
            >
              <template #button-content>
                <i class="mdi mdi-dots-horizontal"></i>
              </template>
              <b-dropdown-item @click="deleteApproval(item.id)"
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import { requestMethods, requestComputed } from "@/state/helpers";
export default {
  components: { VueTypeaheadBootstrap, draggable },
  props: {
    chain: { type: Array },
    chainDropdown: { type: Array },
    requestId: { type: Number },
  },
  data() {
    return {
      text: "",
      approvalUser: "",
      query: "",
      list: [],
    };
  },
  methods: {
    ...requestMethods,
    async deleteApproval(id) {
      await this.deleteApprovalUserAPI({
        approval_id: id,
      });

      this.$emit("refresh");
    },
    statusClass: function(status) {
      let result;
      if (status === "Approved") result = "approved";
      if (status === "Rejected") result = "rejected";

      return result;
    },
    addApprovalUser: async function() {
      const result = this.chainDropdown.find(
        ({ name }) => name === this.approvalUser
      );
      if (!result) {
        const res = await this.findApprovalUserAPI({
          // emails: "usertest@gmail.com",
          emails: this.approvalUser,
        });
        try {
          await this.addApprovalChain(res);
        } catch (err) {
          console.log(err);
        }
      } else {
        this.addApprovalChain(false, result);
      }
    },
    async addApprovalChain(res, result) {
      let payload = {};

      if (res) {
        // Add new user from email
        payload = {
          request_id: this.requestId,
          approval_chain: [
            {
              id: -1,
              approve_user_id: res.data[0].id,
              approval_order: this.list.length + 1,
            },
          ],
        };
        await this.saveApprovalUserAPI(payload);
        await this.fetchApprovalUsersAPI();
        this.$emit("refresh");
      } else {
        payload = {
          request_id: this.requestId,
          approval_chain: [
            {
              id: -1,
              approve_user_id: result.pivot.user_id,
              approval_order: this.list.length + 1,
            },
          ],
        };

        await this.saveApprovalUserAPI(payload);

        this.$emit("refresh");
      }

      this.approvalUser = "";
    },
    async changeOrder() {
      let payload = {
        request_id: this.requestId,
        approval_chain: this.newList,
      };
      // await this.newList.forEach((item) => {
      await this.saveApprovalUserAPI(payload);
      // });

      await this.fetchApprovalUsersAPI();

      this.$emit("refresh");
    },
    statusCheck(status) {
      let result = "";

      if (status.includes("approve")) result = "Approved";
      if (status.includes("reject")) result = "Rejected";

      return result;
    },
  },
  computed: {
    ...requestComputed,
    newList: function() {
      return this.list.map((item, i) => {
        return {
          approval_order: i + 1,
          approve_user_id: item.approval_user_id,
          id: item.id,
        };
      });
    },
    approvalDropdown: function() {
      return this.chainDropdown.map((item) => item.name);
    },
    chainData: function() {
      return this.list.map((item) => {
        let result = {
          approval_user_name: item["approval_user_name"],
          approval_order: item["approval_order"],
          id: item.id,
          status: this.statusCheck(item.status),
        };

        return result;
      });
    },
  },
  mounted() {
    this.list = this.chain;
  },
  watch: {
    chain: {
      handler() {
        this.list = this.chain;
      },
      deep: true,
    },
  },
  beforeDestroy() {},
};
</script>
<style scoped>
.container {
  padding: 0;
  margin-top: 40px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 14px;
}

.icon {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  background: #556ee640;
  color: #556ee6;
  font-size: 16px;
}

.item {
  margin-bottom: 25px;
}

.badge {
  font-size: 11px;
}

.approved {
  background: #37ab00;
}

.rejected {
  background: red;
}
</style>
