<template>
  <div>
    <h4>Comments</h4>
    <div class="container">
      <div
        class="d-flex align-items-start w-100"
        v-for="(item, i) in comment"
        :key="i"
      >
        <div class="d-flex align-items-center">
          <span
            class="icon me-2 d-flex align-items-center justify-content-center"
          >
            <!-- {{ item["approval_user_name"].slice(0, 1) }} -->
          </span>
          <!-- {{ item["approval_user_name"] }} -->
        </div>
        <div class="item w-100">
          <div class="d-flex justify-content-between">
            <h5>{{ item["user_name"] }}</h5>
            <div>
              <span class="d-inline-block">{{
                new Date(item["created_at"]).toLocaleString([], {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                })
              }}</span>
              <b-dropdown
                text="Button text via Prop"
                class="ms-auto"
                variant="white"
                toggle-tag="a"
                menu-class="dropdown-menu-end"
                toggle-class="font-size-16 p-0"
                right
              >
                <template #button-content>
                  <i class="mdi mdi-dots-horizontal"></i>
                </template>
                <b-dropdown-item @click="editComment(item.comment, item.id)"
                  >Edit</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
          <div>
            {{ item["comment"] }}
          </div>
          <!-- <div class="reply d-flex align-items-center">
            <svg
              class="me-2"
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.41667 2.69626V0.207977L0.625 4.56247L4.41667 8.91696V6.36647C7.125 6.36647 9.02083 7.36179 10.375 9.53903C9.83333 6.42868 8.20833 3.31833 4.41667 2.69626Z"
                fill="#34C38F"
              />
            </svg>
            Reply
          </div> -->
        </div>
      </div>
      <div class="d-flex">
        <b-form-input
          v-model="text"
          placeholder="Enter your comment"
          class="me-4"
        ></b-form-input>
        <b-button variant="outline-primary" class="btn" @click="addComment"
          >Add</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { requestMethods } from "@/state/helpers";
export default {
  props: {
    comment: {
      type: Array,
    },
    requestId: {
      type: Number,
    },
  },
  data() {
    return {
      text: "",
      id: null,
    };
  },
  watch: {},
  methods: {
    ...requestMethods,
    addComment() {
      const payload = {
        id: this.id ? this.id : -1,
        request_id: this.requestId,
        comment: this.text,
      };
      this.saveCommentAPI(payload);
      this.$emit("refresh");

      this.id = "";
      this.text = "";
    },
    editComment(comment, id) {
      this.text = comment;
      this.id = id;
    },
  },
};
</script>
<style scoped>
.container {
  margin-top: 40px;
  padding: 0 40px;
}

.item {
  margin-bottom: 25px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 14px;
}

.reply {
  color: #34c38f;
}

.icon {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  background: #556ee640;
  color: #556ee6;
  font-size: 16px;
}
</style>
