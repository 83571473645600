<template>
  <div class="custom-select">
    <select @change="$emit('input', selectedOption)" v-model="selectedOption">
      <option v-for="item in typeList" :key="item.id" :value="item.id">{{
        item.name
      }}</option>
    </select>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedOption: "",
    };
  },
  props: {
    types: {
      type: Array,
    },
  },
  computed: {
    typeList() {
      const initial = [
        {
          id: "",
          name: "Filter",
        },
      ];
      return initial.concat(
        this.types.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        })
      );
    },
  },
};
</script>
<style>
select {
  width: 60px;
}
</style>
