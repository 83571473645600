<template>
    <Layout>
        <PageHeader :title="title" :items="[]" />

        <!-- Component to for displaying requests for the current level -->
        <ApprovalRequests v-if="selected_level != null" />
    </Layout>
        
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ApprovalRequests from '@/components/requests/approval-requests.vue'

import {levelComputed,} from '@/state/helpers'

export default {
    page: {
        title: "Approvals",
    },
    computed:{
        //* load levels state */
        ...levelComputed,
    },
    components: {
        Layout,
        PageHeader,
        ApprovalRequests
    },
    data:()=>({
        title: "Approvals",
        items: [],
    }),
}
</script>
