<!--
@Author: Calvin Green <calvin>
@Date:   2021-11-05T15:24:16+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-11-05T20:06:39+02:00
@Copyright: Diversitude 2021
-->

<template>
  <div class="col-xl-12">
    <div class="card">
      <div class="card-body">
        <div class="d-flex mb-2">
          <h4 class="card-title me-4">Request & Approvals</h4>
          <!-- <input
            class="me-2"
            type="date"
            name="dateStart"
            :max="today"
            v-model="filter.dateStart"
            data-date-format="MMMM DD YYYY"
          /> -->
          <b-form-input
            type="date"
            :max="today"
            v-model="filter.dateStart"
            class="mx-2"
          ></b-form-input>
          to
          <!-- <input
            class="ms-2 me-4"
            type="date"
            name="dateEnd"
            :max="today"
            v-model="filter.dateEnd"
            id="requestFilterDateEnd"
          /> -->
          <b-form-input
            type="date"
            :max="today"
            v-model="filter.dateEnd"
            class="mx-2"
          ></b-form-input>
          <!-- :min="filter.dateStart" -->
          <b-button
            variant="outline-primary"
            class="btn mx-2"
            @click="filterData"
            >Apply</b-button
          >
          <b-button
            variant="outline-primary"
            class="btn mx-2"
            @click="resetFilter"
            >Reset</b-button
          >
          <b-button
            variant="outline-primary"
            class="btn mx-2"
            @click="addRequest"
            >Add Request</b-button
          >
        </div>
        <!-- Pagination -->
        <div class="row" v-if="!isLoading">
          <div class="col-lg-12">
            <ul
              class="pagination pagination-rounded justify-content-center mt-4"
            >
              <li
                class="page-item"
                v-for="num in totalPages"
                :key="num"
                :class="{ active: currentPage === num }"
                @click="currentPage = num"
              >
                <a href="#" class="page-link">{{ num }}</a>
              </li>
            </ul>
          </div>
        </div>
        <Loader v-if="isLoading" :loading="isLoading" class="load-wrapper" />

        <div class="table-wrapper d-flex" v-else>
          <table class="table table-borderless mb-0">
            <thead>
              <tr>
                <th @click="changeTypeSort">
                  Request Title
                  <span
                    class="sort ms-2"
                    :class="[sortTypeOrder ? `sort-${sortTypeOrder}` : '']"
                  />
                </th>
                <th>
                  <div class="d-flex">
                    Type
                    <Dropdown
                      class="ms-2"
                      :types="types"
                      v-model="clickFilter.typeId"
                      @input="currentPage = 1"
                    />
                  </div>
                </th>
                <th>
                  Requested By
                  <span
                    class="sort ms-2"
                    @click="changeSort('requested_by')"
                    :class="[
                      sortBy === 'requested_by' ? `sort-${sortOrder}` : '',
                    ]"
                  />
                </th>
                <th>
                  Date Requested
                  <span
                    class="sort ms-2"
                    @click="changeSort('date_requested')"
                    :class="[
                      sortBy === 'date_requested' ? `sort-${sortOrder}` : '',
                    ]"
                  />
                </th>
                <th>
                  Date Last Reviewed
                  <span
                    class="sort ms-2"
                    @click="changeSort('date_reviewed')"
                    :class="[
                      sortBy === 'date_reviewed' ? `sort-${sortOrder}` : '',
                    ]"
                  />
                </th>
                <th>
                  Status
                  <span
                    class="sort ms-2"
                    @click="changeSort('status')"
                    :class="[sortBy === 'status' ? `sort-${sortOrder}` : '']"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- Add New Request -->
              <tr
                v-if="isAddingRequest && isNewRequest"
                class="item type-bottom"
              >
                <td>
                  <b-form-input
                    v-model="showContent.title"
                    placeholder="Enter title"
                    class="title-input"
                  ></b-form-input>
                </td>
                <td>
                  <vue-typeahead-bootstrap
                    v-model="showContent.type"
                    :showOnFocus="true"
                    :data="typesDropdown"
                    class="type-input"
                  />
                </td>
                <td
                  v-for="(value, name) in newRequest"
                  :key="name"
                  v-text="value"
                />
              </tr>
              <!-- All Requests -->
              <tr v-if="tableDataPerPage.length < 1">
                <td :colspan="this.columns.length">
                  <b-alert show>No Requests</b-alert>
                </td>
              </tr>
              <tr
                class="itemRow flex align-items-center"
                v-for="(item, index) in tableDataPerPage"
                :key="index"
                :class="{
                  'selected-row': item.id && item.id === showContent.id,
                  'type-bottom': isLastItem(item),
                }"
              >
                <td
                  v-for="column in columns"
                  :key="column"
                  class="item"
                  @mouseover="handleIcon(column, item.id)"
                  @mouseleave="isHoverTitle = null"
                >
                  <!-- :class="{ 'type-bottom': isLastItem(item) }" -->
                  <!-- 
                  :class="{ 'type-bottom': isLastItem(item) }" -->
                  <div class="d-flex justify-content-between">
                    <div
                      class="d-flex align-items-center"
                      :class="{
                        title: column === 'title',
                      }"
                    >
                      <!-- 'margin-bottom':
                          column === 'title' && isHoverTitle == item.id, -->
                      <div
                        v-html="item[column]"
                        @click="iconClick('isShowBody', item.id)"
                        :class="{
                          'title-text': !item.isType && column === 'title',
                        }"
                      ></div>
                      <!-- Title Sort Icon-->
                      <span
                        v-if="column === 'title' && item.isType"
                        class="sort ms-2 d-inline-block"
                        :class="[
                          item.title === sortTitleType
                            ? `sort-${sortTitleOrder}`
                            : '',
                        ]"
                        @click="changeSort('titleText', item.title)"
                      />
                    </div>
                    <div v-show="showIcon(column, item.id, item.isType)">
                      <b-link
                        ><IconSet
                          :id="item.id"
                          @iconClick="iconClick"
                          class="ms-2"
                      /></b-link>
                    </div>
                  </div>
                </td>
              </tr>
              <tr
                v-for="index in tableDataLength"
                :key="'empty-row' + index"
                class="emptyRow"
              ></tr>
            </tbody>
          </table>
          <!-- Last Column -->
          <table class="table table-borderless mb-0 request-body">
            <thead>
              <tr>
                <th>General {{ requestTitle }} - Request Body</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="last-column">
                  <!-- Back Icon -->
                  <div v-if="!isShowContent.isShowBody && showContent.id">
                    <b-button
                      variant="outline-primary"
                      class="btn me-4"
                      @click="iconClick('isShowBody', showContent.id)"
                      >Back</b-button
                    >
                    <hr />
                  </div>
                  <!-- Add New / Edit Request -->
                  <div v-if="isAddingRequest">
                    <div class="d-flex mb-2">
                      <b-button
                        variant="outline-primary"
                        class="btn me-4"
                        @click="sendNewRequest"
                        >Save</b-button
                      >
                      <b-button
                        variant="outline-primary"
                        class="btn"
                        @click="isAddingRequest = false"
                        >Cancel</b-button
                      >
                    </div>
                    <div v-if="isAddingRequest">
                      <ckeditor
                        @ready="onEditorReady"
                        :editor="editor"
                        v-model="editorData"
                      ></ckeditor>
                    </div>
                  </div>
                  <!-- Show Body Content -->
                  <div v-if="!isAddingRequest && isShowContent.isShowBody">
                    <div class="d-flex mb-4">
                      <b-button
                        v-if="checkRequestIfNeedApprove"
                        variant="outline-primary"
                        class="btn me-2"
                        @click="requestAction('approve')"
                        >Approve</b-button
                      >
                      <b-button
                        v-if="checkRequestIfNeedApprove"
                        variant="outline-primary"
                        class="btn me-2"
                        @click="requestAction('reject')"
                        >Reject</b-button
                      >
                      <b-button
                        v-if="checkRequestIfNeedApprove"
                        variant="outline-primary"
                        class="btn me-2"
                        @click="requestAction('change')"
                        >Change</b-button
                      >
                      <b-button
                        variant="outline-primary"
                        class="btn me-2"
                        @click="requestAction('escalate')"
                        >Escalate</b-button
                      >
                      <b-button
                        variant="outline-primary"
                        class="btn me-2"
                        @click="requestAction('delete')"
                        >Delete</b-button
                      >
                    </div>
                    <div v-html="showContent.bodyContent"></div>
                  </div>
                  <History
                    v-if="isShowContent.isShowHistory"
                    :history="showContent.historyContent"
                    class="w-100"
                  />
                  <Chain
                    v-if="isShowContent.isShowChain"
                    :chain="showContent.chainContent"
                    :chainDropdown="approvalUsers"
                    :requestId="showContent.id"
                    class="w-100"
                    @refresh="refreshContent('isShowChain')"
                  />
                  <Comment
                    v-if="isShowContent.isShowComment"
                    :comment="showContent.commentContent"
                    :requestId="showContent.id"
                    @refresh="refreshContent('isShowComment')"
                    class="w-100"
                  />
                  <Attachment
                    v-if="isShowContent.isShowAttach"
                    :attachment="showContent.attachment"
                    :requestId="showContent.id"
                    @refresh="refreshContent('isShowAttach')"
                    class="w-100"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Pagination -->
        <div class="row" v-if="!isLoading">
          <div class="col-lg-12">
            <ul
              class="pagination pagination-rounded justify-content-center mt-4"
            >
              <li
                class="page-item"
                v-for="num in totalPages"
                :key="num"
                :class="{ active: currentPage === num }"
                @click="currentPage = num"
              >
                <a href="#" class="page-link">{{ num }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  adminComputed,
  authComputed,
  requestMethods,
  requestComputed,
  levelComputed,
} from "@/state/helpers";
import Loader from "@/components/widgets/loader.vue";
import Dropdown from "./dropdown.vue";
import IconSet from "./titleIconSet.vue";
import History from "./history.vue";
import Chain from "./approvalChain.vue";
import Comment from "./comment.vue";
import Attachment from "./attachment.vue";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

export default {
  components: {
    Loader,
    Dropdown,
    IconSet,
    History,
    Chain,
    Comment,
    Attachment,
    VueTypeaheadBootstrap,
    //ckeditor: CKEditor.component,
  },
  data: () => ({
    isLoading: false,
    rowPerPage: 15,
    currentPage: 1,
    filter: {
      dateStart: "",
      dateEnd: "",
    },
    clickFilter: {
      dateStart: "",
      dateEnd: "",
      typeId: "",
    },
    borderCount: [],
    sortBy: "",
    sortOrder: "",
    sortTypeOrder: "",
    sortTitleType: "",
    sortTitleOrder: "",
    requestItem: [{ title: "" }],
    showTable: true,
    isAddingRequest: false,
    isNewRequest: false,
    editorData: "",
    isHoverTitle: null,
    requestTitle: "",
    query: "",
    newRequest: {},
    editor: DecoupledEditor,
    isShowContent: {
      isShowBody: false,
      isShowChain: false,
      isShowComment: false,
      isShowHistory: false,
      isShowAttach: false,
    },
    showContent: {
      id: null,
      body: "",
      title: "",
      bodyContent: "",
      type: "",
      type_id: "",
      historyContent: [],
      chainContent: [],
      commentContent: [],
      attachment: [],
    },
    columns: [
      "title",
      "type",
      "requested_by",
      "date_requested",
      "date_reviewed",
      "status",
    ],
    requestsData: [],
  }),
  computed: {
    ...authComputed,
    ...levelComputed,
    ...requestComputed,
    ...adminComputed,
    today() {
      const today = new Date();

      return `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(
        -2
      )}-${("0" + today.getDate()).slice(-2)}`;
    },
    dateRequested() {
      let dateArray = this.requestTableData.map((item) =>
        Date.parse(item.date_requested)
      );

      return Math.min(...dateArray);
    },
    dateLastReviewed() {
      let dateArray = this.requestTableData.map((item) =>
        Date.parse(item.date_reviewed)
      );
      return Math.max(...dateArray);
    },
    startRow() {
      return (this.currentPage - 1) * this.rowPerPage;
    },
    endRow() {
      return this.currentPage * this.rowPerPage;
    },
    totalPages() {
      return Math.ceil(this.tableData.length / this.rowPerPage);
    },
    tableDataLength() {
      let fillingRow = this.rowPerPage - this.tableDataPerPage?.length;

      return fillingRow > 0 ? fillingRow : 0;
    },
    typeId() {
      let result = this.types.find((item) => {
        return item.name === this.showContent.type;
      });
      return result?.id;
    },
    typesDropdown() {
      return this.types.map((item) => item.name);
    },
    shownTypes() {
      let types = new Set(this.requestsData.map((item) => item.type.name));
      return _.orderBy([...types], [(item) => item], this.sortTypeOrder);
    },
    tableShownTypes() {
      let types = new Set(
        this.tableDataPerPage
          .filter((item) => {
            if (!item.type) return;
            return item;
          })
          .map((item) => item.type)
      );
      return [...types];
    },
    requestTableData() {
      //All requests reformat
      let final;

      let result = this.requestsData
        .reduce((prev, cur) => {
          let dateRequested = [...cur.log].shift();

          let result = {
            id: cur.id,
            title: "<div>" + cur.title + "</div>",
            titleText: cur.title,
            type: cur.type.name,
            type_id: cur.type.id,
            requested_by: cur.requested_by,
            request_body: cur["request_body"],
            attachment: cur.attachments,
            date_requested: new Date(
              dateRequested["created_at"]
            ).toLocaleString([], {
              year: "numeric",
              month: "numeric",
              day: "2-digit",
            }),
            date_reviewed: new Date(cur.last_modified).toLocaleString([], {
              year: "numeric",
              month: "numeric",
              day: "2-digit",
            }),
            status: this.statusText(cur.status),
            approvals: cur.approvals,
            log: cur.log,
            comments: cur.comments,
          };
          prev.push(result);
          return prev;
        }, [])
        .filter((item) => {
          if (!this.clickFilter.dateStart) return item;
          return (
            this.dateInRange(item.date_requested) ||
            this.dateInRange(item.date_reviewed)
          );
        });

      if (this.sortTitleType) {
        let notSortedRequest = result.filter(
          (item) => item.type !== this.sortTitleType
        );
        let sortedRequest = _.orderBy(
          result.filter((item) => item.type === this.sortTitleType),
          "titleText",
          this.sortTitleOrder
        );
        result = notSortedRequest.concat(sortedRequest);
      }

      final = _.orderBy(result, this.sortBy, this.sortOrder);

      return final;
    },
    tableData() {
      // all request data add types
      let result = [];

      this.shownTypes
        .map((item) => {
          return {
            id: null,
            title: item,
            titleText: item,
            type: "",
            type_id: _.find(this.types, (i) => {
              return i.name === item;
            }).id,
            requested_by: "",
            date_requested: "",
            date_reviewed: "",
            status: "",
            isType: true,
            serial_no: null,
          };
        })
        .forEach((item) => {
          result.push(item);
          this.requestTableData.forEach((request) => {
            if (request.type === item.titleText) {
              result.push(request);
            }
          });
        });

      let finalResult = [];
      result.forEach((item, i) => {
        item.serial_no = i;
        finalResult.push(item);
      });

      return finalResult.filter((item) => {
        if (!this.clickFilter.typeId) return item;
        // filter type
        return (
          item.type_id === this.clickFilter.typeId ||
          item.titleText === this.selectedTypeName(this.clickFilter.typeId)[0]
        );
      });
    },
    tableDataPerPage() {
      return this.tableData.slice(this.startRow, this.endRow);
    },
    filterStartDate() {
      return this.filter.dateStart;
    },
    approvalRequestId() {
      let target = this.tableData
        .filter((item) => item.id === this.showContent.id)
        .map((item) => item.approvals);
      let result = target[0]?.filter(
        (item) => item.approval_user_id === this.core.id
      )[0]?.id;
      return result;
    },
    checkRequestIfNeedApprove() {
      let id = this.showContent.id;
      let targetApprovalArray = this.approval_requests
        .filter((item) => item.id === id)
        .map((item) => item.approvals);
      let result = targetApprovalArray[0]?.filter(
        (item) => item.approval_user_id === this.core.id
      );
      return result?.length > 0 ? true : false;
    },
  },
  watch: {
    tableDataPerPage: {
      handler: function() {
        let result = [];

        this.tableShownTypes.forEach((type) => {
          let number = this.tableDataPerPage.filter((item) => {
            return item.type === type || item.title === type;
          }).length;
          result.push(number);
        });
        let final = [];
        result.forEach((item, i) => {
          if (i > 0) final.push(item + final[i - 1]);
          else final.push(item);
        });

        this.borderCount = final;
      },
      deep: true,
    },

    // filterStartDate: {
    //   handler() {
    //     this.filter.dateEnd = this.filter.dateStart;
    //   },
    //   deep: true,
    // },
  },
  methods: {
    ...requestMethods,
    dateInRange(date) {
      return (
        new Date(this.getGeneralDate(date)) >=
          new Date(this.getGeneralDate(this.clickFilter.dateStart)) &&
        new Date(this.getGeneralDate(date)) <=
          new Date(this.getGeneralDate(this.clickFilter.dateEnd))
      );
    },
    statusText(status) {
      let result;
      if (status.includes("approve")) result = "Approved";
      if (status.includes("reject")) result = "Rejected";
      if (status.includes("pend")) result = "Pending Approval";
      return result;
    },
    showIcon(column, id, isType) {
      return column === "title" && this.isHoverTitle === id && !isType;
    },
    getGeneralDate(date) {
      return (
        new Date(date).getFullYear() +
        "-" +
        (new Date(date).getMonth() + 1) +
        "-" +
        new Date(date).getDate()
      );
    },
    filterData() {
      this.currentPage = 1;
      this.clickFilter.dateStart = this.filter.dateStart;
      this.clickFilter.dateEnd = this.filter.dateEnd;
    },
    resetFilter() {
      let startDate = new Date(this.dateRequested);
      let endDate = new Date(this.dateLastReviewed);
      this.filter.dateStart = `${startDate.getFullYear()}-${(
        "0" +
        (startDate.getMonth() + 1)
      ).slice(-2)}-${("0" + startDate.getDate()).slice(-2)}`;

      this.filter.dateEnd = `${endDate.getFullYear()}-${(
        "0" +
        (endDate.getMonth() + 1)
      ).slice(-2)}-${("0" + endDate.getDate()).slice(-2)}`;

      this.clickFilter.dateStart = this.clickFilter.dateEnd = "";
    },
    changeTypeSort() {
      this.sortTypeOrder = this.sortTypeOrder === "asc" ? "desc" : "asc";
    },
    changeSort(item, type) {
      if (item === "titleText") {
        this.sortBy = "";
        this.sortOrder = "";
        this.sortTitleType = type;
        this.sortTitleOrder = this.sortTitleOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortTitleType = "";
        this.sortTitleOrder = "";
        this.sortBy = item;
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      }
    },
    async sendNewRequest() {
      // Validation
      let noType = true;
      if (!this.typeId) noType = this.showContent.type;
      if (!this.showContent.title || !noType || !this.editorData) {
        this.newRequestStyle();
        return;
      }
      // Send API
      if (!this.typeId && !this.showContent.id) {
        let response = await this.saveTypesAPI({
          type_name: this.showContent.type,
        });
        this.showContent.type_id = response.data.request_type_id;

        await this.sendEditedRequest();
      } else {
        this.sendEditedRequest();
      }
    },
    newRequestStyle() {
      const typeInput = document.querySelector(".type-input input");
      const titleInput = document.querySelector(".title-input");
      const ckEditor = document.querySelector(".ck-content");
      typeInput.style.border = "1px solid #ced4da";
      titleInput.style.border = "1px solid #ced4da";

      if (!this.showContent.type) typeInput.style.border = "1px solid red";
      if (!this.showContent.title) titleInput.style.border = "1px solid red";
      if (!this.editorData) ckEditor.style.border = "1px solid red";
    },
    async sendEditedRequest() {
      const payload = {
        id: `${this.showContent.id ? this.showContent.id : "-1"}`,
        // level_id: this.core.user_level.id,
        level_id: this.selected_level.id,
        title: this.showContent.title,
        type_id: this.showContent.type_id || this.typeId,
        request_body: this.editorData,
      };
      let res;
      try {
        res = await this.editRequestAPI(payload);
        await this.fetchRequestsAPI(this.selected_level.id);
      } catch (error) {
        console.log(error);
      } finally {
        this.showContent.bodyContent = this.editorData;
        this.requestsData = this.requests;
        this.isAddingRequest = false;
        this.isNewRequest = false;
        this.getTargetData(res.data.request_item_id);
        this.isShowContent.isShowBody = true;
      }
    },
    async refreshContent(page) {
      try {
        await this.fetchRequestsAPI(this.selected_level.id);
      } catch (error) {
        console.log(error);
      } finally {
        this.requestsData = this.requests;
        if (page) this.iconClick(page, this.showContent.id);
        if (!page) this.iconClick();
      }
    },
    levelUpdated() {
      this.refreshContent();
      this.fetchApprovalUsersAPI(this.selected_level.id);
    },
    async requestAction(type) {
      const params = this.currentUser;
      switch (type) {
        case "approve":
          await this.approveReject(
            {
              approval_request_id: this.approvalRequestId,
              action: "approve",
              feedback: "Request Approval Feedback Text ",
            },
            params
          );
          this.refreshContent("isShowBody");
          break;
        case "reject":
          await this.approveReject(
            {
              approval_request_id: this.approvalRequestId,
              action: "reject",
              feedback: "Request Reject Feedback Text ",
            },
            params
          );
          this.refreshContent("isShowBody");
          break;
        case "change":
          this.isAddingRequest = true;
          this.editorData = this.showContent.body;
          this.isShowContent.isShowBody = false;

          break;
        case "escalate":
          this.iconClick("isShowChain", this.showContent.id);
          break;
        case "delete":
          this.deleteRequestAPI({ request_id: this.showContent.id });
          this.refreshContent();
          break;
      }
    },
    handleIcon(column, id) {
      if (!id) return;
      if (column !== "title") return;
      this.isHoverTitle = id;
    },
    addRequest() {
      this.isAddingRequest = true;
      this.isNewRequest = true;
      this.editorData = "";
      Object.keys(this.isShowContent).forEach((key) => {
        this.isShowContent[key] = false;
      });
      Object.keys(this.showContent).forEach((key) => {
        this.showContent[key] = "";
      });
      this.newRequest = {
        requested_by: this.core.full_name,
        date_requested: new Date().toLocaleDateString(),
        date_reviewed: new Date().toLocaleDateString(),
        status: "Pending Approval",
      };
    },
    iconClick(type, id) {
      this.isAddingRequest = false;
      this.isNewRequest = false;
      Object.keys(this.showContent).forEach((key) => {
        this.showContent[key] = "";
      });
      Object.keys(this.isShowContent).forEach((key) => {
        this.isShowContent[key] = false;
      });
      if (type) this.isShowContent[type] = true;
      if (id) this.getTargetData(id); //1108 added
      if (!id) this.requestTitle = "";
    },
    getTargetData(id) {
      let showData = this.requestTableData.filter((item) => {
        return item.id === id;
      });

      this.requestTitle = "-" + showData[0].titleText;
      this.showContent.title = showData[0].titleText;
      this.showContent.id = showData[0].id;
      this.showContent.type_id = showData[0].type_id;
      this.showContent.body = showData[0]["request_body"];
      this.showContent.attachment = showData[0]["attachment"];
      this.showContent.historyContent = showData[0]["log"];
      this.showContent.chainContent = showData[0]["approvals"];
      this.showContent.commentContent = showData[0]["comments"];
      this.showContent.bodyContent = showData[0]["request_body"];

      return showData;
    },
    // isLastItem(index) {
    //   return this.borderCount.includes(index);
    // },
    isLastItem(item) {
      if (!item.type_id) return;

      let tartgetTypeArray = this.tableDataPerPage
        .filter((data) => data.type_id === item.type_id)
        .map((data) => data.serial_no);

      let max = Math.max(...tartgetTypeArray);

      return max === item.serial_no;
    },
    selectedTypeName(typeId) {
      return this.types
        .filter((item) => {
          return item.id === typeId;
        })
        .map((item) => {
          return item.name;
        });
    },
    onEditorReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
  },
  mounted() {
    this.isLoading = true;

    Promise.all([
      this.fetchRequestsAPI(this.selected_level.id),
      this.fetchApprovalUsersAPI(this.selected_level.id),
      this.fetchTypes(),
    ]).then(() => {
      this.requestsData = this.requests;
      this.$eventHub.$on("newLevelSelected", this.levelUpdated);
      this.resetFilter();
      this.isLoading = false;
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("newLevelSelected");
  },
};
</script>

<style lang="scss" scoped>
.load-wrapper {
  height: 100px;
}

.table-wrapper {
  width: 1700px;

  .last-column {
    overflow: scroll;
    display: block;
    height: 850px; //To avoid that the content is to long@last column
    width: 600px;
  }

  table {
    border-radius: 0.25 rem;
  }

  // title style
  thead th {
    &:nth-child(1) {
      width: 260px;
    }
    &:nth-child(2) {
      width: 143px;
    }
  }

  th,
  tbody {
    border: 1px solid #ced4da;
  }

  th {
    vertical-align: bottom;
    padding: 0.75rem;
    text-align: left;
    height: 48px;
  }

  tr {
    height: 45px;

    &.selected-row {
      background: #edeff5;
    }

    & .emptyRow {
      &:first-child {
        border-top: 1px solid #ced4da;
      }
    }
  }

  td {
    border-right: 1px solid #ced4da;
  }

  .request-body {
    width: 600px;
    overflow: scroll;
    margin-right: 2.5rem;
  }

  .request-body th,
  .request-body tbody {
    border-left: none;
  }
  .request-body td {
    border-right: none;
  }

  .alert-info {
    color: #306391;
    background-color: #dcedfc;
    border-color: #cbe4fb;
  }
}

.sort {
  cursor: pointer;
  width: 15px;
  height: 10px;
  background-image: url(../../assets/images/sort-icon.svg);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  opacity: 0.3;
  &-asc {
    transform: rotate(180deg);
    opacity: 1;
  }
  &-desc {
    opacity: 1;
  }
}

.card {
  overflow: scroll;
}

.title {
  cursor: pointer;

  .title-text {
    position: relative;
    padding-left: 15px;
    max-width: 150px;
    word-wrap: break-word;

    &:before {
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: black;
      top: 7px;
      left: 0;
      content: "";
    }
  }
}

.margin-bottom {
  margin-bottom: 0px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  /* display: none;
  -webkit-appearance: none; */
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background: transparent;
  margin: 0;
}

input[type="date"] {
  position: relative;
  height: 21px;
  width: 90px;
  padding-right: 0;
}

.btn {
  padding: 0.3rem 0.75rem;
}

.type-bottom {
  border-bottom: 1px solid #ced4da;

  .border-red {
    border: 1px solid red;
  }
}

// .ck-content {
//   border: 1px solid red;
// }
</style>
