<template>
  <div class="d-flex">
    <div class="icon-item">
      <span class="tooltiptext">Chain</span>
      <svg
        @click="$emit('iconClick', 'isShowChain', id)"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="me-2 icon"
      >
        <path
          d="M8.83877 5.16127C10.3947 6.71879 10.3733 9.2159 8.84814 10.7495C8.84528 10.7527 8.84189 10.7561 8.83877 10.7592L7.08877 12.5092C5.54529 14.0527 3.03412 14.0524 1.49087 12.5092C-0.0526198 10.9659 -0.0526198 8.45449 1.49087 6.91127L2.45717 5.94497C2.71342 5.68872 3.15472 5.85903 3.16795 6.22116C3.18482 6.68267 3.26758 7.14634 3.42029 7.5941C3.47201 7.74572 3.43506 7.91342 3.32178 8.02671L2.98097 8.36751C2.25113 9.09735 2.22824 10.2857 2.95089 11.0227C3.68068 11.767 4.88021 11.7714 5.6156 11.036L7.36559 9.28626C8.09973 8.55212 8.09666 7.36551 7.36559 6.63444C7.26922 6.53825 7.17213 6.46351 7.0963 6.41129C7.04265 6.37445 6.99836 6.32559 6.96695 6.26859C6.93554 6.21159 6.91788 6.14804 6.91539 6.08301C6.90508 5.80783 7.00257 5.52426 7.22002 5.30682L7.7683 4.75851C7.91208 4.61473 8.13762 4.59708 8.30434 4.71343C8.49527 4.84675 8.6741 4.99661 8.83877 5.16127ZM12.5089 1.49091C10.9657 -0.0523674 8.45453 -0.0525758 6.91104 1.49091L5.16104 3.24091C5.15792 3.24403 5.15453 3.24742 5.15167 3.25054C3.62651 4.78419 3.60513 7.28129 5.16104 8.83881C5.3257 9.00347 5.50452 9.15331 5.69544 9.28662C5.86216 9.40298 6.08773 9.3853 6.23148 9.24155L6.77976 8.69324C6.99721 8.47579 7.09471 8.19223 7.0844 7.91704C7.08191 7.85201 7.06425 7.78846 7.03284 7.73147C7.00143 7.67447 6.95713 7.6256 6.90349 7.58876C6.82765 7.53655 6.73057 7.46181 6.63419 7.36561C5.90312 6.63455 5.90005 5.44793 6.63419 4.7138L8.38419 2.96406C9.11958 2.22867 10.3191 2.2331 11.0489 2.97734C11.7716 3.71432 11.7487 4.9027 11.0188 5.63254L10.678 5.97335C10.5647 6.08663 10.5278 6.25434 10.5795 6.40595C10.7322 6.85371 10.815 7.31738 10.8318 7.77889C10.8451 8.14103 11.2864 8.31134 11.5426 8.05509L12.5089 7.08879C14.0524 5.54559 14.0524 3.03414 12.5089 1.49091Z"
          fill="black"
        />
      </svg>
    </div>
    <div class="icon-item">
      <span class="tooltiptext">Comment</span>
      <svg
        @click="$emit('iconClick', 'isShowComment', id)"
        class="me-2 icon"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9999 0.333374H1.99992C1.08065 0.333374 0.333252 1.08077 0.333252 2.00004V9.50004C0.333252 10.4193 1.08065 11.1667 1.99992 11.1667H4.49992V13.3542C4.49992 13.5391 4.65096 13.6667 4.81242 13.6667C4.87492 13.6667 4.94002 13.6485 4.99731 13.6042L8.24992 11.1667H11.9999C12.9192 11.1667 13.6666 10.4193 13.6666 9.50004V2.00004C13.6666 1.08077 12.9192 0.333374 11.9999 0.333374ZM12.4166 9.50004C12.4166 9.72921 12.2291 9.91671 11.9999 9.91671H7.83325L7.49992 10.1667L5.74992 11.4792V9.91671H1.99992C1.77075 9.91671 1.58325 9.72921 1.58325 9.50004V2.00004C1.58325 1.77087 1.77075 1.58337 1.99992 1.58337H11.9999C12.2291 1.58337 12.4166 1.77087 12.4166 2.00004V9.50004Z"
          fill="black"
        />
      </svg>
    </div>
    <div class="icon-item">
      <span class="tooltiptext">History</span>
      <svg
        @click="$emit('iconClick', 'isShowHistory', id)"
        class="me-2 icon"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.6666 6.98743C13.6734 10.6605 10.6779 13.6641 7.00475 13.6667C5.41833 13.6678 3.96113 13.1148 2.81599 12.1906C2.51822 11.9502 2.49597 11.504 2.76656 11.2334L3.06943 10.9305C3.30086 10.6991 3.67032 10.6738 3.92672 10.8772C4.7704 11.5467 5.83811 11.9463 6.99991 11.9463C9.73392 11.9463 11.9461 9.73361 11.9461 7.00004C11.9461 4.26603 9.73349 2.0538 6.99991 2.0538C5.68771 2.0538 4.49591 2.56372 3.61099 3.39606L4.97535 4.76041C5.24631 5.03138 5.0544 5.49466 4.67123 5.49466H0.763359C0.525806 5.49466 0.333252 5.30211 0.333252 5.06455V1.15668C0.333252 0.773508 0.796531 0.5816 1.0675 0.85254L2.3947 2.17974C3.59156 1.03595 5.21368 0.333374 6.99991 0.333374C10.6776 0.333374 13.6598 3.31133 13.6666 6.98743ZM8.80335 9.10528L9.06741 8.76576C9.28617 8.4845 9.2355 8.07915 8.95424 7.86041L7.86013 7.00942V4.20434C7.86013 3.84802 7.57128 3.55918 7.21497 3.55918H6.78486C6.42854 3.55918 6.1397 3.84802 6.1397 4.20434V7.85087L7.898 9.21845C8.17927 9.43718 8.58459 9.38654 8.80335 9.10528Z"
          fill="black"
        />
      </svg>
    </div>
    <div class="icon-item">
      <span class="tooltiptext">Attachment</span>
      <svg
        @click="$emit('iconClick', 'isShowAttach', id)"
        class="me-2 icon"
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.29294 12.4725C-0.228674 10.9025 -0.200315 8.37064 1.32903 6.80632L6.79152 1.2188C7.94559 0.0382833 9.82149 0.0381792 10.9757 1.2188C12.1186 2.38792 12.12 4.27424 10.9757 5.44473L6.21397 10.3107C5.4365 11.1059 4.16624 11.0948 3.40195 10.2847C2.66562 9.50421 2.68924 8.26715 3.43976 7.49944L7.18306 3.67562C7.34405 3.5112 7.60785 3.50838 7.77228 3.66935L8.36762 4.25213C8.53207 4.41312 8.53486 4.67692 8.37389 4.84135L4.63098 8.66481C4.50254 8.79619 4.49463 9.01449 4.61411 9.14116C4.72796 9.26184 4.90694 9.26384 5.02265 9.14546L9.78433 4.27955C10.2951 3.75711 10.2951 2.90651 9.78405 2.38377C9.28433 1.87266 8.48309 1.8724 7.98316 2.38377L2.52065 7.97127C1.61536 8.89731 1.6014 10.3963 2.48963 11.3127C3.37531 12.2266 4.80694 12.2278 5.69426 11.3202L10.175 6.73689C10.3358 6.57234 10.5996 6.56937 10.7642 6.73023L11.36 7.31262C11.5245 7.47348 11.5275 7.73728 11.3666 7.90184L6.8859 12.4851C5.33444 14.072 2.82489 14.0532 1.29294 12.4725Z"
          fill="black"
        />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.icon-item {
  position: relative;

  .icon {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  .tooltiptext {
    visibility: hidden;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 0 5px;

    /* Position the tooltip */
    background-color: #d3d5db;
    color: black;
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
    transform: translate(30%, -140%);
    font-size: 12px;

    &:after {
      content: "";
      position: absolute;
      top: calc(100% + -1px);
      right: 22px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #d3d5db transparent transparent transparent;
    }
  }

  &:nth-child(2) {
    .tooltiptext {
      transform: translate(20%, -140%);
    }
  }

  &:nth-child(4) {
    .tooltiptext {
      transform: translate(20%, -140%);
    }
  }

  &:hover .tooltiptext {
    visibility: visible;
  }
}
</style>
