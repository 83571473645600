<template>
  <div>
    <h4>History</h4>
    <div class="container">
      <div class="item" v-for="(item, i) in history" :key="i">
        <h5>{{ item["action"] }}</h5>
        <!-- <p>
        </p> -->
        <div class="d-flex justify-content-between">
          <div>
            {{
              new Date(item["created_at"]).toLocaleString([], {
                year: "numeric",
                month: "short",
                day: "2-digit",
              })
            }}
          </div>
          <div>
            <span
              ><svg
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.5001 0.56311C5.69672 0.56311 6.66677 1.53316 6.66677 2.72978C6.66677 3.92639 5.69672 4.89644 4.5001 4.89644C3.30348 4.89644 2.33344 3.92639 2.33344 2.72978C2.33344 1.53316 3.30348 0.563111 4.5001 0.56311M4.50014 5.97974C6.89431 5.97974 8.83348 6.94932 8.83348 8.1464V9.22974H0.166809V8.1464C0.166809 6.94932 2.10598 5.97974 4.50014 5.97974Z"
                  fill="#74788D"
                />
              </svg>
            </span>
            {{ item["user_name"] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    history: {
      type: Array,
    },
  },
  watch: {},
  methods: {},
};
</script>
<style scoped>
.container {
  padding: 0 40px;
}

.item {
  margin-bottom: 25px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 14px;
}
</style>
